export const profile = {
  followers: "Abonnés",
  post: "Post",
  interest: "Intérêt de l’artiste",
  about_artist: "À propos de l'artiste",
  feed: "Feed",
  artroom: "Salle d'art",
  weeks_ago: "il y a des semaines",
  years_ago: "il y a des années",
  months_ago: "il y a des mois",
  days_ago: "il y a jours",
  hours_ago: "il y a des heures",
  minutes_ago: "il y a minutes",
  just_now: "tout à l' heure",
  sorry: "Désolée!",
  no_data: "Nous n'avons trouvé aucune donnée",
  out_of_Stock: "En rupture de stock",
  view_more: "voir plus",
  sale: "À vendre",
  exhibited: "Exhibited",
  art: "Art",
  available_piece: "Pièce disponible",
  copies_left: "il ne reste que des exemplaires",
  buy_now: "Acheter maintenant",
  description: "Description",
  weight: "Poids",
  width: "Largeur",
  height: "Hauteur",
  depth: "Profondeur",
  price: "Prix",
};
