import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import Paragraph from "../../common/Paragraph";
import DummyProfile from "../../assets/images/dummy_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { paths } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import { tokenSelector } from "../../redux/slices/userSlice";
import { FaPlayCircle, FaThumbsUp } from "react-icons/fa";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import {
  getArtRoomDetailsById,
  getFeedComments,
  getFeedDetailsById,
} from "../../services/profileService";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { ArtDetails } from "../../interface/ArtDetails";
import { CommentInterface } from "../../interface/Comment";
import About from "../about/About";
import { profileSelector } from "../../redux/slices/profileSlice";
import { useTranslation } from "react-i18next";
import { IoMdChatboxes } from "react-icons/io";
import moment from "moment";
import { BsArrowLeft } from "react-icons/bs";
import ProfileHeader from "../header-profile/ProfileHeader";

function FeedCommentDetail() {
  const profileData = useSelector(profileSelector);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(tokenSelector);
  const location = useLocation();
  const item = location.state?.item;
  const itemsPerPage = 10;
  const profileUrlToken = `${paths.web.profile}?token=${token}`;
  const artUrlToken = `${paths.web.artDetail}/${id}?token=${token}`;

  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [commentData, setCommentData] = useState<CommentInterface[]>([]);
  const [feedDetails, setFeedDetails] = useState<ArtDetails | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isReplyOpen, setIsReplyOpen] = useState<number | null>(null);

  const timeDifference = (date: string) => {
    const today = moment();
    const createdDate = moment(date);
    let diff = today.diff(createdDate, "years");
    let message = t("profile.years_ago");
    if (diff === 0) {
      diff = today.diff(createdDate, "months");
      message = t("profile.months_ago");
      if (diff === 0) {
        diff = today.diff(createdDate, "weeks");
        message = t("profile.weeks_ago");
        if (diff === 0) {
          diff = today.diff(createdDate, "days");
          message = t("profile.days_ago");
          if (diff === 0) {
            diff = today.diff(createdDate, "hours");
            message = t("profile.hours_ago");
            if (diff === 0) {
              diff = today.diff(createdDate, "minutes");
              message = t("profile.minutes_ago");
            }
          }
        }
      }
    }
    if (diff === 0) {
      return t("profile.just_now");
    }
    return `${diff} ${message}`;
  };

  console.log("profileData", profileData);

  const fetchComments = async (page: number) => {
    if (id) {
      let queryString = `?page=${page}&limit=10`;
      dispatch(showLoader());

      try {
        const response = await getFeedComments(parseInt(id), queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.comments) {
          setCommentData((prevData) => [
            ...prevData,
            ...response.data.comments,
          ]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (currentPage) {
      fetchComments(currentPage);
    }
  }, [currentPage, id]);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchFeedDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getFeedDetailsById(parseInt(id));
        if (response?.data?.code === 200) {
          setFeedDetails(response?.data?.publication);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchFeedDetails(id);
    }
  }, [id]);

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const handleBuyNowClick = () => {
    const urlWithToken = `${paths.web.checkout}/${id}?token=${token}`;
    navigate(urlWithToken);
  };

  const breadcrumbPages = [
    { name: "Profile", path: profileUrlToken },
    { name: feedDetails?.title || "", path: artUrlToken },
  ];

  const handleReply = (index: number) => {
    setIsReplyOpen(isReplyOpen === index ? null : index); // Toggle the reply box for the clicked comment
  };

  const handleBack = () => {
    const urlWithToken = `${paths.web.profile}?token=${token}`;
    navigate(urlWithToken);
  };
  console.log("commentData", commentData);
  return (
    <>
      {/* User Profile */}
      <ProfileHeader />
      {/* End User Profile */}

      {/* Post Data */}
      <section className="md:py-10 py-[30px]">
        <div className="container">
          <div className="flex lg:flex-nowrap flex-wrap lg:gap-6 sm:gap-4 gap-3">
            {<About profileData={profileData} />}

            <div className="w-full">
              <div className="flex items-center gap-3 py-4 border-b border-light-grey mb-4">
                <BsArrowLeft
                  size={24}
                  onClick={handleBack}
                  className="cursor-pointer"
                />
                <Paragraph text24>Post</Paragraph>
              </div>
              <div className="">
                <div
                  key={feedDetails?.id}
                  className="relative block pb-[30px] md:ps-[66px] ps-[40px] pt-[10px]"
                >
                  <div className="flex items-center absolute left-0 top-0 z-[1]">
                    <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                      <img
                        src={getProfileImage(profileData?.avatar ?? "")}
                        alt="profile-photo"
                      />
                    </div>
                    <div className="bg-white flex items-center gap-4 px-5 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative -left-[15px] -z-[1]">
                      <Paragraph text22>
                        @{feedDetails?.user?.nickname}
                      </Paragraph>
                      <Paragraph
                        text18
                        className="!leading-[17px] font-normal !text-dark-grey"
                      >
                        {timeDifference(feedDetails?.createdAt ?? "")}
                      </Paragraph>
                    </div>
                  </div>
                  {(feedDetails?.video || feedDetails?.images) && (
                    <>
                      <div className="relative pb-6">
                        <div className="block rounded-[20px] overflow-hidden relative w-auto max-h-[390px] ">
                          {feedDetails?.video ? (
                            <div
                              style={{
                                display: "inline-block",
                                position: "relative",
                              }}
                            >
                              <img
                                id={`image-${feedDetails.id}`}
                                src={feedDetails?.video?.imageUrl}
                                className={
                                  "max-h-[390px] h-full rounded-[20px] bg-light-grey"
                                }
                              />
                              <span className="absolute inset-0 flex items-center justify-center">
                                <FaPlayCircle
                                  size={40}
                                  className="text-base"
                                  onClick={() =>
                                    openLightbox(feedDetails?.video?.videoUrl)
                                  }
                                />
                              </span>
                            </div>
                          ) : (
                            <img
                              src={
                                feedDetails?.images &&
                                feedDetails?.images[0]?.imageUrl
                              }
                              alt="feed-img"
                              className="max-h-[390px] h-full rounded-[20px] bg-light-grey cursor-pointer"
                            />
                          )}
                        </div>
                        <div className="flex items-center gap-5 absolute bottom-0">
                          <button className="bg-site-green cursor-default rounded-full w-[30px] h-[30px] flex items-center justify-center relative">
                            <FaThumbsUp
                              size={14}
                              color="#fff"
                              className="cursor-default"
                            />
                            {item?.countLikes > 0 && (
                              <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]">
                                {item?.countLikes}
                              </span>
                            )}
                          </button>
                          <button className="bg-site-yellow rounded-full cursor-default w-[30px] h-[30px] flex items-center justify-center relative">
                            <IoMdChatboxes
                              size={18}
                              color="#fff"
                              className="cursor-default"
                            />
                            {item?.countComments > 0 && (
                              <span
                                className="bg-site-red min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]"
                                // onClick={() => handleCommentSection(item)}
                              >
                                {item?.countComments}
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {feedDetails?.video || feedDetails?.images ? (
                    <Paragraph text18 className="!font-normal mt-4">
                      <span>{feedDetails?.text?.contentText}</span>
                    </Paragraph>
                  ) : (
                    <>
                      <Paragraph text18 className="!font-normal mt-[50px]">
                        <span>{feedDetails?.text?.contentText}</span>
                      </Paragraph>
                      <div className="flex items-center gap-5  bottom-0">
                        <button className="bg-site-green cursor-default rounded-full w-[30px] h-[30px] flex items-center justify-center relative">
                          <FaThumbsUp
                            size={14}
                            color="#fff"
                            className="cursor-default"
                          />
                          {item?.countLikes > 0 && (
                            <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]">
                              {item?.countLikes}
                            </span>
                          )}
                        </button>
                        <button className="bg-site-yellow rounded-full cursor-default w-[30px] h-[30px] flex items-center justify-center relative">
                          <IoMdChatboxes
                            size={18}
                            color="#fff"
                            className="cursor-default"
                          />
                          {item?.countComments > 0 && (
                            <span
                              // onClick={() => handleCommentSection(item)}
                              className="bg-site-red min-w-[18px]  text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]"
                            >
                              {item?.countComments}
                            </span>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>

                {commentData?.map((item: any, index: any) => {
                  return (
                    <>
                      <ul>
                        <li className="flex items-start justify-between gap-3 pb-4 border-b border-medium-grey mb-4 xl:w-[75%]">
                          <div className="flex items-start gap-2 w-full">
                            <div className="md:w-[42px] md:h-[42px] w-[36px] h-[36px] rounded-full flex-shrink-0 overflow-hidden">
                              <img
                                src={getProfileImage(item?.user?.avatar)}
                                alt="profile-photo"
                              />
                            </div>
                            <div className="w-full">
                              <Paragraph
                                text16
                                className="flex items-end gap-5 font-semibold !leading-[20px]"
                              >
                                {item?.user?.name + " " + item?.user?.surname}
                                <span className="text-dark-grey text-[14px] font-normal leading-[20px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[6px]">
                                  {timeDifference(item?.createdAt ?? "")}
                                </span>
                              </Paragraph>
                              <Paragraph
                                text16
                                className="flex items-end gap-2 !font-normal !leading-[20px]"
                              >
                                {item?.text}
                              </Paragraph>

                              <div className="flex items-center gap-5 mt-[10px]">
                                <div className=" inline-flex items-end gap-[6px]">
                                  <FaThumbsUp
                                    size={13}
                                    color="#828282"
                                    className=""
                                  />
                                  <Paragraph
                                    text14
                                    className={`leading-[8px] !font-normal ${
                                      item?.likedByMe
                                        ? "text-green-500"
                                        : "text-dark-grey"
                                    }`}
                                  >
                                    0
                                  </Paragraph>
                                </div>
                                {item?.countResponses > 0 && (
                                  <Paragraph
                                    className="border-b cursor-pointer border-dark-grey !font-normal leading-[8px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[2px]"
                                    onClick={() => handleReply(index)}
                                    text14
                                  >
                                    {`${item?.countResponses} Replies`}
                                  </Paragraph>
                                )}
                              </div>

                              {isReplyOpen === index && (
                                <ul className="mt-5">
                                  <li className="flex items-start gap-3 ">
                                    <div className="md:w-[40px] md:h-[40px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                                      <img
                                        src={getProfileImage(
                                          item?.user?.avatar
                                        )}
                                        alt="profile-photo"
                                      />
                                    </div>
                                    <div>
                                      <Paragraph
                                        text16
                                        className="flex items-end gap-5 font-semibold !leading-[20px]"
                                      >
                                        {item?.user?.name +
                                          " " +
                                          item?.user?.surname}
                                        <span className="text-dark-grey text-[14px] font-normal leading-[20px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[6px]">
                                          {timeDifference(
                                            item?.createdAt ?? ""
                                          )}
                                        </span>
                                      </Paragraph>
                                      <Paragraph
                                        text16
                                        className="flex items-end gap-2 !font-normal !leading-[20px]"
                                      >
                                        {item?.text}
                                      </Paragraph>

                                      <div className="flex items-center gap-5 mt-[10px]">
                                        <div className=" inline-flex items-end gap-[6px]">
                                          <FaThumbsUp
                                            size={13}
                                            color="#828282"
                                            className=""
                                          />
                                          <Paragraph
                                            text14
                                            className="!text-dark-grey leading-[8px] !font-normal"
                                          >
                                            0
                                          </Paragraph>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                          {/* <div className=" flex items-end gap-2">
                            <FaThumbsUp
                              size={16}
                              color="#828282"
                              className=""
                            />
                            <Paragraph
                              text14
                              className="!text-dark-grey leading-[8px]"
                            >
                              0 Likes
                            </Paragraph>
                          </div> */}
                        </li>
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Post Data */}
      {lightboxOpen && (
        <div>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default FeedCommentDetail;
